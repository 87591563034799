import React from "react";

import { Layout } from "../components/index";

export default class ServerError extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <div className="p-strip">
          <section className="page">
            <h2>Sorry, we have a server error.</h2>
            <p>
              If this problem persists, please contact me directly on email at{" "}
              <a href="mailto:richard@richardlatimer.com">
                richard@richardlatimer.com
              </a>
            </p>
            <p>Thank you for your patience and understanding.</p>
          </section>
        </div>
      </Layout>
    );
  }
}
